import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import log from '../../logger';
import useMuiDndEditorTable from '../../shared/useMuiDndEditorTable';
import { getAppUserCustomFields, updateAppUserCustomFields } from '../api/appUserApi';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import { AppUserConstants } from '../definitions';
import { toLower } from 'lodash';
import { selectAppUserSettings } from '../../store/rootSelectors';
import { useSelector } from 'react-redux';
import rfc from 'react-fast-compare';

const useAppUserCustomFields = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState('');
  const [modalIndex, setModalIndex] = useState(0);
  const { allowAppUsersCustomAuth } = useSelector(selectAppUserSettings, rfc);
  const form = useForm({
    defaultValues: { appUserCustomFields: [] },
    resolver: yupResolver(
      yup
        .object()
        .shape({
          appUserCustomFields: yup
            .array()
            .of(
              yup.object().shape({
                title: yup.string().required('Title is required'),
                field: yup
                  .string()
                  .required('Unique name is required')
                  .notOneOf(
                    ['subtenantId', 'userType', 'phone'],
                    'Field cannot be one of the restricted values'
                  ),
                type: yup.string().required('Type is required'),
                options: yup
                  .array()
                  .test(
                    'options-validation',
                    'At least one option is required',
                    function (options) {
                      const { type } = this.parent;
                      if (['select', 'radio', 'checkbox'].includes(type)) {
                        return options && options.length > 0;
                      }
                      return true;
                    }
                  )
                  .of(yup.string().required('Option is required'))
                  .nullable(),
                required: yup.boolean(),
              })
            )
            .unique('Unique ID field must be unique', (field) => toLower(field.field)),
        })
        .required()
    ),
  });
  const [saveTime, setSaveTime] = useState(Date.now());
  const {
    data: fields = [],
    isLoading,
    isFetching,
    isFetched,
  } = useQuery({
    queryKey: ['useAppUserCustomFields', saveTime],
    queryFn: getAppUserCustomFields,
  });
  const hasData = !isFetched && isLoading && isFetching && !fields;

  const columns = useMemo(
    () => [
      { name: 'Title', field: 'title', width: 14 },
      {
        name: 'WYSIWYG',
        field: 'html',
        width: 7,
        action: (idx) => {
          const fieldValue = form.getValues('appUserCustomFields')[idx];
          setShowModal(!showModal);
          setModalIndex(idx);
          setItem(fieldValue);
        },
      },
      {
        name: 'Unique ID',
        field: 'field',
        transformValue: (v = '') =>
          v
            .toUpperCase()
            .replaceAll(/[^ A-Z_]+/g, '')
            .replaceAll(/\s+/g, '_')
            .replaceAll(/_{2,}/g, '_'),
        hideIf: (row) => row.canRemove === false || row.lockField === true,
        width: 14,
      },
      {
        name: 'Type',
        field: 'type',
        width: 14,
        inputType: 'dropdown',
        hideIf: (row) => row.canRemove === false || row.id,
        options: [
          { label: 'Checkbox', value: 'checkbox' },
          { label: 'Date', value: 'date' },
          { label: 'Email', value: 'email' },
          { label: 'Header', value: 'header' },
          { label: 'Integer', value: 'integer' },
          { label: 'Note', value: 'note' },
          { label: 'Number', value: 'number' },
          { label: 'Phone', value: 'phone' },
          { label: 'Radio', value: 'radio' },
          { label: 'Select', value: 'select' },
          { label: 'Text Area', value: 'textarea' },
          { label: 'Text', value: 'text' },
        ],
      },
      {
        name: 'Min Length',
        field: 'minLength',
        width: 7,
        hideIf: (row) => !['number', 'text', 'textarea', 'integer'].includes(row?.type),
        inputType: 'number',
      },
      {
        name: 'Max Length',
        field: 'maxLength',
        width: 7,
        hideIf: (row) => !['number', 'text', 'textarea', 'integer'].includes(row?.type),
        inputType: 'number',
      },
      {
        name: 'Options',
        field: 'options',
        hideIf: (row) =>
          row.canRemove === false ||
          !['select', 'checkbox', 'radio'].includes(row.type) ||
          row.lockField,
        width: 15,
        inputType: 'autocomplete',
        options: [],
      },
      {
        name: 'Default',
        field: 'default',
        hideIf: (row) =>
          row.canRemove === false ||
          row.id ||
          !['text', 'select', 'textarea', 'radio'].includes(row.type),
        width: 15,
      },
      {
        name: 'Required',
        field: 'required',
        width: 5,
        hideIf: (row) =>
          row.canRemove === false || ['header', 'note'].includes(row.type) || row.disable === true,
        inputType: 'checkbox',
      },
      {
        name: 'Hidden At Signup',
        field: 'hideBeforeAuth',
        width: 5,
        hideIf: (row) => row.canRemove === false,
        inputType: 'checkbox',
      },
      {
        name: 'Hidden In App',
        field: 'hidden',
        width: 5,
        hideIf: (row) => row.canRemove === false,
        inputType: 'checkbox',
      },
      {
        name: 'Hide Title',
        field: 'hideTitle',
        width: 5,
        hideIf: (row) => row.canRemove === false,
        inputType: 'checkbox',
      },
      {
        name: 'Admin Required',
        field: 'adminRequired',
        width: 5,
        hideIf: (row) => row.canRemove === false,
        inputType: 'checkbox',
      },
      {
        name: 'Admin Hidden',
        field: 'adminHidden',
        width: 5,
        hideIf: (row) => row.canRemove === false,
        inputType: 'checkbox',
      },
      {
        name: 'Disable',
        field: 'disable',
        width: 5,
        hideIf: (row) =>
          row.canRemove === false || ['header', 'note'].includes(row.type) || row.required === true,
        inputType: 'checkbox',
      },

      ...(allowAppUsersCustomAuth
        ? [
            {
              name: 'Unique Custom Auth Fields',
              field: 'allowCustomAuth',
              width: 5,
              inputType: 'checkbox',
              hideIf: (row) => row.type === 'header',
            },
          ]
        : []),
      {
        name: '',
        noSort: true,
        isRemove: true,
        hideIf: (row) => !row.canRemove || row.lockField,
        width: 5,
        confirm: {
          title: `Remove Custom Field`,
          message: `After you confirm and save ALL data from users associated with this including all entries captured by users for this application.`,
        },
      },
    ],
    []
  );

  const { addRow, PreRenderedTable, setRowField } = useMuiDndEditorTable({
    form,
    columns,
    formRowsKey: 'appUserCustomFields',
    defaultRow: { title: '', type: 'text', options: [], required: false },
    stickyHeader: true,
  });

  const { trigger, control, reset } = form;
  const appUserCustomFields = useWatch({ control, name: 'appUserCustomFields' });

  useEffect(() => {
    if (fields && Array.isArray(fields)) {
      reset({ appUserCustomFields: fields });
    }
  }, [fields, reset]);

  useEffect(() => {
    appUserCustomFields.forEach(({ type, options } = {}, idx) => {
      if (options?.length > 0 && type && !['select', 'checkbox', 'radio'].includes(type)) {
        setRowField(idx, 'options', []);
      }
    });
  }, [appUserCustomFields, trigger]);

  const handleSave = async () => {
    try {
      await form.handleSubmit(async ({ appUserCustomFields }) => {
        await updateAppUserCustomFields(appUserCustomFields);
        setSaveTime(Date.now());
        dispatch(
          navigateToPluginPage([AppUserConstants.pluginId, AppUserConstants.defaultPageName])
        );
      })();
    } catch (error) {
      log.error('Validation failed:', error);
    }
  };
  return {
    addRow,
    PreRenderedTable,
    fields,
    isLoading: hasData,
    handleSave,
    modalProps: {
      handleUpdateItem: (value) => {
        setItem(value);
        form.setValue(`appUserCustomFields[${modalIndex}].html`, value);
      },
      item,
      closeModal: () => setShowModal(false),
      open: showModal,
      title: 'WYSIWYG Modal',
    },
  };
};

export default useAppUserCustomFields;
